<template>
  <b-overlay :show="getFormLoading" spinner-variant="primary">
    <b-form>
      <b-form-group label="Header">
        <b-form-textarea
          v-model="form.HeaderContent"
          autocomplete="off"
          placeholder="Adja meg a header fájl tartalmát"
          rows="14"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group label="Footer">
        <b-form-textarea
          v-model="form.FooterContent"
          autocomplete="off"
          placeholder="Adja meg a footer fájl tartalmát"
          rows="14"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group label="CSS fájl neve">
        <b-form-input
          v-model="form.CssFajlNeve"
          autocomplete="off"
          placeholder="Adja meg az egyedi CSS fájl nevét"
          rows="14"
        ></b-form-input>
        <b-form-text> {{ form.CssFajlPath }}</b-form-text>
      </b-form-group>
      <b-form-group label="Android config">
        <v-jsoneditor
          v-model="form.AndroidConfig"
          autocomplete="off"
          placeholder="Adja meg az Android config fájl tartalmát"
          rows="14"
          height="500px"
          :options="jsonoptions"
        ></v-jsoneditor>
      </b-form-group>
      <b-form-group label="IOS config">
        <v-jsoneditor
          v-model="form.IOSConfig"
          autocomplete="off"
          placeholder="Adja meg az IOS config fájl tartalmát"
          rows="14"
          height="500px"
          :options="jsonoptions"
        ></v-jsoneditor>
      </b-form-group>

      <div class="sticky-bottom py-2 mt-4 bg-white" style="z-index: 9999">
        <k-button
          @click="OnSubmit"
          variant="primary"
          label="Mehet"
          icon="fa-check"
          :loading="saveFormLoading"
        ></k-button>
      </div>
    </b-form>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import cloneDeep from 'lodash/cloneDeep';
import { AppStoreTypes } from '../../store/modules/app';
import { hidrateForm } from '../../utils/common';
import settings from '../../settings';
import { projektService } from '../../services/ProjektService';

let getDefaultForm = () => {
  return {
    ProjektId: -1,
    HeaderContent: '',
    FooterContent: '',
    CssFajlNeve: '',
    AndroidConfig: '',
    IOSConfig: '',
    CssFajlPath: '',
  };
};

export default {
  name: 'layout-config-form',
  data() {
    return {
      getFormLoading: false,
      saveFormLoading: false,
      form: cloneDeep(getDefaultForm()),
      settings,
      jsonoptions: {
        mode: 'code'
      }
    };
  },
  mounted() {},
  created() {
    this.GetForm();
  },
  methods: {
    async GetForm() {
      this.getFormLoading = true;
      try {
        let data = await projektService.GetContents();
        data.IOSConfig = JSON.parse(data.IOSConfig ?? '{}');
        data.AndroidConfig = JSON.parse(data.AndroidConfig ?? '{}');
        hidrateForm(this, this.form, data);
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.getFormLoading = false;
    },
    async OnSubmit() {
      this.saveFormLoading = true;
      try {
        let data = {
          ...this.form,
        };
        data.IOSConfig = JSON.stringify(data.IOSConfig ?? '{}');
        data.AndroidConfig = JSON.stringify(data.AndroidConfig ?? '{}');
        await projektService.SaveContents(data);
        NotificationFunctions.SuccessAlert(
          'Projekt config',
          'A projekt módosítása megtörtént'
        );
        this.GetForm();
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.saveFormLoading = false;
    },
  },
  computed: {
    ...mapGetters({
      user: AppStoreTypes.user.get,
    }),
  },
  watch: {},
  props: {},
};
</script>
